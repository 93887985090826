/* global gapi, google */

import { setLoadingMessage, hideLoadingMessage, clearModalStack, setIsLoading } from './base';
import {
  displayFile,
  applyOpennessState,
  setDirty,
  setLastSyncAt,
  setOrgFileErrorMessage,
} from './org';
import { persistField } from '../util/settings_persister';

import { addSeconds } from 'date-fns';

import _ from 'lodash';

export const signOut = () => (dispatch, getState) => {
  switch (getState().syncBackend.get('client', {}).type) {
    case 'Dropbox':
      // `dropboxAccessToken` is a legacy token that was relevant
      // prior to switching to OAuth 2 and PKCE. Still deleting it
      // here for a consistent state in users localStorage.
      persistField('dropboxAccessToken', null);
      persistField('dropboxRefreshToken', null);
      persistField('codeVerifier', null);
      break;
    case 'Google Drive':
      const token = gapi.client.getToken();
      if (token) {
        persistField('googleDriveAccessToken', null);
        google.accounts.oauth2.revoke(token);
        gapi.client.setToken('');
        window.location.reload();
      }
      break;
    default:
  }

  persistField('authenticatedSyncService', null);

  dispatch({ type: 'SIGN_OUT' });
  dispatch(clearModalStack());
  dispatch(hideLoadingMessage());
};

export const setCurrentFileBrowserDirectoryListing = (
  directoryListing,
  hasMore,
  additionalSyncBackendState
) => ({
  type: 'SET_CURRENT_FILE_BROWSER_DIRECTORY_LISTING',
  directoryListing,
  hasMore,
  additionalSyncBackendState,
});

export const setIsLoadingMoreDirectoryListing = isLoadingMore => ({
  type: 'SET_IS_LOADING_MORE_DIRECTORY_LISTING',
  isLoadingMore,
});

export const getDirectoryListing = path => (dispatch, getState) => {
  dispatch(setLoadingMessage('Getting listing...'));

  const client = getState().syncBackend.get('client');
  client
    .getDirectoryListing(path)
    .then(({ listing, hasMore, additionalSyncBackendState }) => {
      dispatch(setCurrentFileBrowserDirectoryListing(listing, hasMore, additionalSyncBackendState));
      dispatch(hideLoadingMessage());
    })
    .catch(error => {
      dispatch(hideLoadingMessage());
      const error_summary = _.get(error, 'error.error_summary') || '';
      if ([400, 401].includes(error.status) || error_summary.includes('expired_access_token')) {
        dispatch(signOut());
      } else {
        alert('There was an error retrieving files!');
        console.error(error);
      }
    });
};

export const loadMoreDirectoryListing = () => (dispatch, getState) => {
  dispatch(setIsLoadingMoreDirectoryListing(true));

  const client = getState().syncBackend.get('client');
  const currentFileBrowserDirectoryListing = getState().syncBackend.get(
    'currentFileBrowserDirectoryListing'
  );
  client
    .getMoreDirectoryListing(currentFileBrowserDirectoryListing.get('additionalSyncBackendState'))
    .then(({ listing, hasMore, additionalSyncBackendState }) => {
      const extendedListing = currentFileBrowserDirectoryListing.get('listing').concat(listing);
      dispatch(
        setCurrentFileBrowserDirectoryListing(extendedListing, hasMore, additionalSyncBackendState)
      );
      dispatch(setIsLoadingMoreDirectoryListing(false));
    });
};

export const pushBackup = (pathOrFileId, contents) => {
  return (dispatch, getState) => {
    const client = getState().syncBackend.get('client');
    switch (client.type) {
      case 'Dropbox':
        const pathParts = pathOrFileId.split('/');

        let directoryPath = '';
        for (let index = 1; index <= pathParts.length - 2; index++) {
          directoryPath += '/';
          directoryPath += pathParts[index];
        }
        const fileName = pathParts[pathParts.length - 1];
        client.createFile(`${directoryPath}/org-web-bak/${fileName}`, contents);
        break;
      case 'Google Drive':
        pathOrFileId = pathOrFileId.startsWith('/') ? pathOrFileId.substr(1) : pathOrFileId;
        client.duplicateFile(pathOrFileId, fileName => `${fileName}.org-web-bak`);
        break;
      default:
    }
  };
};

export const downloadFile = path => {
  return (dispatch, getState) => {
    dispatch(setLoadingMessage('Downloading file...'));

    getState()
      .syncBackend.get('client')
      .getFileContents(path)
      .then(fileContents => {
        dispatch(setDirty(false));
        dispatch(hideLoadingMessage());
        dispatch(pushBackup(path, fileContents));
        dispatch(setLastSyncAt(addSeconds(new Date(), 5)));
        dispatch(displayFile(path, fileContents));
        dispatch(applyOpennessState());
      })
      .catch(() => {
        dispatch(hideLoadingMessage());
        dispatch(setIsLoading(false));
        dispatch(setOrgFileErrorMessage('File not found'));
      });
  };
};
