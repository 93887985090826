import React from 'react';

import './stylesheet.css';

import classNames from 'classnames';

export default ({ buttons, selectedButton, useEqualWidthTabs, useIconView, onSelect }) => {
  const handleButtonClick = buttonName => () => onSelect(buttonName);

  const containerClassName = classNames('tab-buttons', {
    'tab-buttons--equal-width-tabs': useEqualWidthTabs,
  });

  const style = {
    gridTemplateColumns: useEqualWidthTabs ? `repeat(${buttons.length}, 1fr)` : null,
  };

  return (
    <div className={containerClassName} style={style}>
      {buttons.map(button => {
        let buttonName;
        let buttonValue;
        if (button.name && button.value) {
          buttonName = button.name;
          buttonValue = button.value;
        } else {
          buttonName = button;
          buttonValue = button;
        }
        const className = classNames('tab-buttons__btn', {
          'tab-buttons__btn--selected': buttonValue === selectedButton,
        });

        return (
          <div key={buttonValue} className={className} onClick={handleButtonClick(buttonValue)}>
            {useIconView ? <i className={buttonName} /> : buttonName}
          </div>
        );
      })}
    </div>
  );
};
